import React, { useState }  from 'react'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Hero from 'sections/hero/Hero.js'
import AboutOne from 'sections/about/AboutOne.js'
import ServicesTwo from 'sections/services/ServicesTwo.js'
import PortfolioThree from 'sections/portfolio/PortfolioThree.js'
import ClientsOne from 'sections/clients/ClientsOne.js'
import ContactCreative from 'sections/contact/ContactCreative.js'
class Index extends React.Component {

  render() {

    const { site, wp, allWpPortfolio } = this.props.data
    

    return (
      <div>
        <Helmet>
          <title>{site.meta.title}</title>
          <meta name="description" content={site.meta.description} />
        </Helmet>
        <Layout
          isHome={true}
          sections={['home', 'about', 'services', 'portfolio','clients', 'contact']}
        >
          <Hero />
          <AboutOne />
          <ServicesTwo />
          <PortfolioThree projects={allWpPortfolio.nodes}/>
          <ClientsOne />
          <ContactCreative 
            address={wp.opcionesGenerales.settings.direccion}
            phone={wp.opcionesGenerales.settings.telefono}
            phoneVisual={wp.opcionesGenerales.settings.telefonoVisual}
            email={wp.opcionesGenerales.settings.email}
          />
        </Layout>
      </div>
    )
  }

}

export default Index


export const creativeOneQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
    wp {
      opcionesGenerales {
        settings {
          direccion
          email
          telefono
          telefonoVisual
        }
      }
    }
    allWpPortfolio(filter: {portfolio: {featured: {eq: true}}}) {
      nodes {
        id
        slug
        title
        excerpt
        featuredImage {
          node {
            localFile {
              absolutePath
              publicURL
              relativePath
            }
          }
        }
        portfolio {
          cliente
          tecnologia
          link {
            url
          }
        }
      }
    }
  }
`
/*
export const query = graphql`
  mutation MyMutation {
    __typename
    createSubmission(input: {clientMutationId: "test", email: "alejandro.narmona@gmail.com", mensaje: "probandooooo", nombre: "Alejandro", telefono: "232323232323"}) {
      clientMutationId
      data
      success
    }
}*/